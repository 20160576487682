import { Button, Center } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { TbArrowBackUp } from "react-icons/tb";

export const showErrorToast = (message: string) => {
  notifications.show({
    message,
    color: "red",
  });
};

export const showSuccessToast = (message: string) => {
  notifications.show({
    message,
    color: "green",
  });
};

export const showUndoToast = (
  message: string,
  undoCallback: () => void = () => {}
) => {
  const notifId = notifications.show({
    message: (
      <Center className="flex flex-row justify-between">
        {message}
        <Button
          className="text-primary-700"
          variant="transparent"
          onClick={undoClicked}
        >
          <TbArrowBackUp color="green" />
          &nbsp;Undo
        </Button>
      </Center>
    ),
    color: "green",
  });

  function undoClicked() {
    notifications.hide(notifId);
    undoCallback();
  }
};
